import { ChainId } from '@/config/networks'
import { SuperTokenInfo } from '@superfluid-finance/tokenlist'
import { iSuperTokenAddress, superBoringABI, superBoringAddress } from 'evm-contracts'
import { iSuperTokenABI } from 'evm-contracts'
import { useMemo } from 'react'
import { zeroAddress } from 'viem'
import { useAccount, useChainId, useReadContract, useReadContracts } from 'wagmi'
import boringLogo from '../../public/boring_logo.svg'
import { mockToken } from '../constants'

export const useRewardToken = () => {
  const { address: user } = useAccount()
  const userChainId = useChainId() as ChainId

  const rewarTokenFragment = useMemo(() => {
    return {
      address: iSuperTokenAddress[userChainId],
      abi: iSuperTokenABI
    }
  }, [userChainId])

  const {
    data: balance,
    refetch: refetchBalance,
    isLoading
  } = useReadContract({
    query: {
      initialData: 0n,
      enabled: Boolean(user)
    },
    address: superBoringAddress[userChainId],
    abi: superBoringABI,
    functionName: 'getStakeableAmount',
    args: [user ?? zeroAddress]
  })

  const { data } = useReadContracts({
    contracts: [
      {
        ...rewarTokenFragment,
        functionName: 'name'
      },
      {
        ...rewarTokenFragment,
        functionName: 'symbol'
      },
      {
        ...rewarTokenFragment,
        functionName: 'decimals'
      }
    ]
  })

  const tokenMeta = useMemo(() => {
    if (data) {
      const [name, symbol, decimals] = data.map(d => d.result)

      return {
        name: name as string,
        symbol: symbol as string,
        decimals: decimals as number
      }
    }
  }, [data])

  const token: SuperTokenInfo = useMemo(
    () => ({
      ...mockToken,
      ...tokenMeta,
      address: iSuperTokenAddress[userChainId],
      logoURI: boringLogo.src,
      tags: ['supertoken']
    }),
    [userChainId, tokenMeta]
  )

  return {
    token,
    balance,
    refetchBalance,
    isLoading
  }
}
